.layout {
  background-size: "cover";
  background: white;
}

.container {
  max-width: 408px;
  margin: auto;
  /* margin-top: 70px; */
}

.title {
  text-align: center;
  /* color      : #626262 !important; */
  color: rgba(0, 0, 0, 0.85);
  font-size: 30px !important;
  letter-spacing: -0.04em !important;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.btn-login {
  background: #d80100;
  border-color: #d80100;
}

.btn-login:focus {
  background: #ca2323;
  border-color: #ca2323;
}

.btn-login:hover {
  background: #ca2323;
  border-color: #ca2323;
}

.btn-login:active {
  background: #d80100;
  border-color: #d80100;
}

.input:active {
  border-color: #d9d9d9;
}

.input:hover {
  border-color: #d9d9d9;
}

.input:focus {
  border-color: #d9d9d9;
}

input[type="checkbox"]:checked {
  border-color: #d9d9d9 !important;
}

.titleContainer {
  display: "flex";
  align-items: center;
  justify-content: center;
  margin-bottom: 28px;
  text-align: center;
  font-size: 40px;
  font-weight: 600;
  font-family: cursive;
  line-height: 1.4;
}

.name-profile {
  font-size: 16px;
}

.imageContainer {
  display: "flex";
  align-items: "center";
  justify-content: "center";
  margin-bottom: 28px;
}

.logo-login {
  width: 120px;
  height: 120px;
}

.login-form-forgot {
  float: right;
}
