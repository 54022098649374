html {
  --antd-wave-shadow-color: #ff5252;
}

html,
body {
  overflow-x: hidden;
}

* {
  font-family: "Montserrat";
}

.ant-btn-primary {
  border-color: #ff5252;
  background: #ff5252;
}
.ant-btn-primary:focus {
  border-color: #ff5252;
  background: #ff5252;
}
.ant-btn-primary:hover {
  border-color: #ff5252;
  background: #ff5252;
}
.ant-btn-primary:active {
  border-color: #ff5252;
  background: #ff5252;
}

.btn-create {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}
.btn-create:hover {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}
.btn-create:active {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}
.btn-create:focus {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}
.ant-modal-header {
  background: #ff5252 !important;
  color: white !important;
}
.ant-modal-title {
  color: white;
  font-size: 20px;
}

.logo {
  display: flex;
  gap: 10px;
  justify-content: center;
  position: relative;
}
.title-brand {
  color: white !important;
}

.icon-logo {
  font-size: 20px;
  color: white;
}

.actions {
  font-size: 16px;
  padding: 3px 10px;
}

.submit {
  text-align: end;
  margin-top: 10px;
  display: flow-root;
  align-items: center;
  margin-left: 40rem;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
}

.btn-delete {
  color: #fa541c;
  border-color: #fa541c;
  background: #fff;
}

.btn-delete:hover {
  color: #e07b59;
  border-color: #e97a55;
  background: #fff;
}

.btn-delete:active {
  color: #fa541c;
  border-color: #fa541c;
  background: #fff;
}

.btn-active {
  background: #fff;
  border-color: rgb(103, 190, 35);
  color: rgb(103, 190, 35);
}

.btn-active:hover {
  background: #fff;
  border-color: rgb(103, 190, 35);
  color: rgb(103, 190, 35);
}

.btn-active:active {
  background: #fff;
  border-color: rgb(103, 190, 35);
  color: rgb(103, 190, 35);
}

.btn-deactive {
  background: #fff;
  border-color: rgb(250, 84, 28);
  color: rgb(250, 84, 28);
}

.btn-deactive:hover {
  background: #fff;
  border-color: rgb(250, 84, 28);
  color: rgb(250, 84, 28);
}

.btn-deactive:active {
  background: #fff;
  border-color: rgb(250, 84, 28);
  color: rgb(250, 84, 28);
}

.ant-layout-sider {
  background-color: #2c343f !important;
}

.logo-img {
  // margin-top: 20px;
}
.info-inter {
  text-align: center;
}
.accept-cancel-call {
  display: flex;
  justify-content: center;
  gap: 40px;
  margin-top: 40px;
}
.cancel-call-my-4 {
  margin-top: 20px;
}
.accept-call {
  cursor: pointer;
}
.cancle-call {
  cursor: pointer;
}
.icon-call-inter {
  font-size: 20px;
  color: white;
}
.name-call {
  margin-top: 15px;
}
.info-name {
  font-size: 25px;
  font-weight: 600;
  color: black;
  margin: 0;
}
.info-name:nth-child(2) {
  margin-bottom: 10px;
}
.info-icon {
  margin-bottom: 10px;
}
.list-interpreters {
  margin-top: 15px;
  padding: 15px;
  height: 350px;
  overflow-y: auto;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.item-filter {
  color: #ff5252 !important;
  font-size: 1.05rem;
  width: 100%;
}

.filter_requesters {
  margin-bottom: 20px;
  width: 100%;
}
.filter_item_request {
  display: flex;
  gap: 20px;
}
.btn-filter {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}
.btn-filter:focus {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}
.input-filter {
  border-bottom: 1px solid;
  border-radius: 0px;
  border-right: none;
  border-top: none;
  border-left: none;
}
.input-filter:active {
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0px;
  border-right: none;
  border-top: none;
  border-left: none;
}

.btn-filter:hover {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}

.btn-filter:active {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}
.title-address {
  color: #ff5252 !important;
}

.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0px;
}

.ant-table-thead > tr > th {
  font-weight: 600;
  font-size: 15px;
  color: #000000db;
  background-color: rgb(242 242 242);
  border-bottom: 1px solid rgb(217, 217, 217);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #ff5252;
  border-color: #ff5252;
}

.ant-form-item-control-input-content {
  // display: flex;
  flex-direction: column;
}
.list-permission {
  margin-left: 20px;
}
.breadcrumb {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  font-size: 25px;
}

.breadcrumb-item {
  font-size: 17px;
}
.ant-form-item-label > label {
  font-weight: 500;
}
.exportExcel {
  background-color: #ff5252;
  border-color: #ff5252;
  color: white;
}
.exportExcel:active {
  background-color: #ff5252;
  border-color: #ff5252;
  color: white;
}
.exportExcel:hover {
  background-color: #ff5252;
  border-color: #ff5252;
  color: white;
}
.exportExcel:focus {
  background-color: #ff5252;
  border-color: #ff5252;
  color: white;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #ff5252;
}

.mess-role {
  color: #ff5252;
}

.row-options {
  margin-bottom: 9px;
}
.row-number-first {
  margin-bottom: -2px;
}
.row-number {
  margin-bottom: -4px;
}

.search-item {
  width: 50%;
}
.ant-input[disabled] {
  color: #626262;
  background-color: #ffffff00;
}

li.ant-pagination-options {
  display: none;
}
.add-language-interpreter {
  cursor: pointer;
  border: 1px solid;
  padding: 10px;
  border-radius: 50%;
  background-color: #ff5252;
  color: white;
  font-size: 20px;
}
.list-language {
  background-color: #eceeef;
  padding: 12px 10px;
}
.result-list-language {
  padding: 12px 10px;
  border-bottom: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-right: 1px solid #f0f0f0;
}
.ant-checkbox-checked .ant-checkbox-inner[disabled] {
  background-color: #b0b0b0;
  color: white;
}

.search-form {
  display: flex;
  gap: 20px;
  width: 100%;
}

@keyframes hideAnimation {
  to {
    visibility: hidden;
    width: 0;
    height: 0;
  }
}
.add-pay-rate {
  background-color: #ff5252;
  border-color: #ff5252;
  color: white;
}
.add-pay-rate:focus {
  background-color: #ff5252;
  border-color: #ff5252;
  color: white;
}
.add-pay-rate:active {
  background-color: #ff5252;
  border-color: #ff5252;
  color: white;
}
.add-pay-rate:hover {
  background-color: #ff5252;
  border-color: #ff5252;
  color: white;
}
h5.ant-typography {
  font-weight: 500;
}
.ant-checkbox-disabled .ant-checkbox-inner {
  background-color: #b0b0b0 !important;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner::after {
  border-color: white;
}
.item-interpreter {
  padding: 10px 10px;
}

.btn-create-disable-interpreter {
  color: black !important;
}

.ant-layout-content {
  background-color: #f0f2f5;
}

.ant-page-header.has-breadcrumb {
  background-color: rgb(255, 255, 255);
  color: rgb(98, 98, 98);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.ant-pagination-item {
  border: none;
}

.ant-pagination-item-active {
  border: 1px solid #c0bcbc;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none;
}
.sider-item-collapsed {
  background-color: red;
}
.sider-item-no-collapsed {
  background-color: pink;
}

.ant-breadcrumb-link {
  font-size: 25px;
  color: #545454;
}
.content-customer-invoice {
  width: 32%;
}

.checkbox-child {
  margin-left: 15px;
}
.checkbox-parent {
  margin-top: 24px;
}

.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(
    .ant-pagination-size-changer
  ):hover
  .ant-select-selector {
  border-color: #00a400 !important;
  box-shadow: none;
}

.ant-select-focused.ant-select:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: #00a400 !important;
  box-shadow: none;
}
.btn-create-disable-interpreter {
  color: black !important;
}

.ant-layout-content {
  background-color: #f0f2f5;
}

.ant-page-header.has-breadcrumb {
  background-color: rgb(255, 255, 255);
  color: rgb(98, 98, 98);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 6px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;
}
.ant-pagination-item {
  border: none;
}

.ant-pagination-item-active {
  border: 1px solid #c0bcbc;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none;
}
.sider-item-collapsed {
  background-color: red;
}
.sider-item-no-collapsed {
  background-color: pink;
}

.ant-breadcrumb-link {
  font-size: 25px;
  color: #545454;
}

.ant-pagination-total-text {
  position: absolute;
  left: 0;
  font-size: 16px;
  font-weight: 500;
  margin-left: 16px;
  bottom: 0;
}
.ant-table-wrapper {
  border: 1px solid rgb(195 195 195 / 50%);
  border-radius: 5px;
}
.text-profile {
  margin: 0px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
}
.image-info {
  display: flex;
  flex-direction: column;
  -webkit-box-align: center;
  // align-items: center;
}
.name-profile {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.6;
  padding: 10px 0px;
}
.note-title {
  text-align: center;
  word-wrap: break-word;
  white-space: normal;
  color: #626262d9;
  padding: 10px 0px;
}
.name-profile-user {
  font-size: 10px;
  color: "#000000b5";
  padding: 10px;
}
.dropdown-user {
  background: #fff;
  display: flex;
  gap: 5px;
}
.dropdown-user:hover {
  border-color: #ff5252;
  color: black;
  background: #fff;
}
.dropdown-user:focus {
  border-color: #ff5252;
  color: black;
  background: #fff;
}
.dropdown-user:active {
  border-color: #ff5252;
  color: black;
  background: #fff;
}

.contain-avartar {
  background: #3c4043;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.icon-user-dot {
  position: relative;
}
.profile-user {
  padding: 5px;
}
.icon-down-outline {
  margin-top: 5px;
}
.change-status {
  font-size: 16px;
  font-weight: 500;
}
.container-change-status {
  display: flex;
  gap: 10px;
  padding-right: 10px;
  padding-top: 10px;
  margin-left: 360px;
}
.container-change-status-mobile {
  display: flex;
  gap: 10px;
  padding-right: 10px;
  padding-top: 10px;
  margin-left: 75px;
}
.container-notice-sm {
  display: flex;
  gap: 10px;
  padding-top: 0px;
  margin-left: 0px;
}
.ant-switch-checked:focus {
  color: rgb(255 80 88);
}

.ant-switch-checked:hover {
  color: rgb(255 80 88);
}
.ant-switch:hover {
  background: rgb(255 80 88);
}
.sider-right {
  background-color: #2c343f;
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 720px;
  // width: 240px;
  padding: 0 10px;
}
.sider-right-container {
  display: flex;
  flex-direction: column;
  position: relative;
}
.interpreter-online {
  padding: 10px;
  gap: 10px;
  cursor: pointer;
  position: relative;
}
.interpreter-online:hover {
  background-color: #f0f0f0;
  border-radius: 5px;
}

.language-online {
  padding: 0 10px;
  gap: 10px;
  cursor: pointer;
  position: relative;
}
.language-online-mobile {
  padding: 10px 10px;
  gap: 10px;
  cursor: pointer;
  position: relative;
}

.language-online:hover {
  background-color: #3f4957;
  border-radius: 5px;
}
.name-profile {
  color: #00a400;
  font-size: 15px;
}
.ant-avatar-string {
  font-size: 40px;
}
.dashboard-inter-online {
  position: absolute;
}

.profile-detail {
  transform: scale(1);
  border-radius: 8px;
  padding: 10px 0px;
}

.list-profile {
  padding: 0 10px 10px;
  border-radius: 8px;
}

.show-interpreter-online {
  display: none;
}

@media (max-width: 767px) {
  .show-interpreter-online {
    display: block;
  }
  .sider-right {
    display: none;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #929191;
}

/* Table */
.ant-table-content::-webkit-scrollbar {
  height: 10px;
}

.ant-table-content::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
  border-radius: 20px;
}

.switch-mobile {
  margin-top: 4px;
}

.ant-layout-sider-zero-width-trigger {
  display: none;
}

.ant-drawer-content {
  background: #2c343f !important;
}
.report-language {
  display: flex;
  justify-content: space-between;
}
.back-to-login-page {
  text-align: center;
  justify-content: center;
  display: flex;
  padding: 10px 20px 20px 20px;
  font-size: 16px;
}

.my-input-number::-webkit-inner-spin-button,
.my-input-number::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.my-input-number[type="number"] {
  -moz-appearance: textfield;
}
.ant-notification-notice-message {
  font-size: 16px !important;
  font-weight: 500;
}
a.ant-notification-notice-close {
  font-size: 16px !important;
  font-weight: 500;
}
