.list-language-customer {
  margin-top: 10px;
}

.table_column {
  border: none !important;
  font-size: 16px;
}

.call-icon {
  color: #2a132e;
  cursor: pointer;
}

.item-call-video {
  gap: 20px !important;
}

.column-call {
  display: flex;
  justify-content: flex-end;
}

.title-language {
  color: #ff5252 !important;
  font-size: 18px !important;
  padding: 10px 0px;
  font-weight: 550 !important;
  margin-top: 20px;
}

.btn-media {
  padding : 0px;
  border: none;
  background-color: transparent !important;
  height: 2.5rem;
  height: 40px;
  width: 40px;
}

.img-media {
  width: 100%;
  height: 100%;
}

.btn-media:hover {
  filter : inherit;
  background-color: transparent;
}
