.checkbox-group {
  margin-left: 30px;
  display: flex;
  flex-direction: column;
}
.form-item-py {
  margin-bottom: 10px;
}
.add-bill-rate {
  margin-bottom: 10px;
  background-color: #ff5252;
  border-color: #ff5252;
  color: white;
}
.add-bill-rate:focus {
  background-color: #ff5252;
  border-color: #ff5252;
  color: white;
}
.add-bill-rate:active {
  background-color: #ff5252;
  border-color: #ff5252;
  color: white;
}
.add-bill-rate:hover {
  background-color: #ff5252;
  border-color: #ff5252;
  color: white;
}
.input-number {
  width: 100%;
}