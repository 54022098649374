.ant-modal-header {
  background-color: #fff;
  color: #333;
}
header {
  background: #f0293e;
  color: #fff;
  text-align: center;
  flex-grow: 0;
  margin-bottom: 2em;
}
.col-12 {
  width: 51%;
  height: 82%;
  margin: 0 auto;
}
.col-6 {
  width: calc((100% - 10px) / 2);
  display: flex;
  align-items: center;
  height: 79%;
}
.col-4 {
  width: calc((100% - 10px) / 3);
  display: flex;
  align-items: center;
  height: 51%;
}
.col-3 {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 555px;
  height: 312px;
}

h1 {
  font-weight: 300;
  padding: 0.4em 0;
}

#root {
  min-height: 100vh;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  background: #ffffff;
  flex-grow: 1;
}

.form-call {
  max-width: 300px;
  margin: 0 auto;
}

h2 {
  font-weight: 300;
  margin-bottom: 1em;
  text-align: center;
}

.form-call > div {
  width: 100%;
  margin-bottom: 1em;
}

.form-call > div > label {
  display: block;
  margin-bottom: 0.3em;
}

.form-call > div > input {
  display: block;
  width: 100%;
  font-size: 16px;
  padding: 0.4em;
  border-radius: 6px;
  border: 1px solid #333e5a;
}

button {
  background: #333e5a;
  color: #fff;
  font-size: 16px;
  padding: 0.4em;
  border-radius: 6px;
  border: 1px solid transparent;
}

button:hover {
  filter: brightness(150%);
}

.room {
  position: relative;
  height: 100vh;
  background: #202124;
}

.room > h3 {
  text-align: center;
  font-weight: 300;
  margin-bottom: 1em;
}

.local-participant {
  width: 15%;
  height: 20%;
  position: absolute;
  right: 0;
  bottom: 0;
  background: gray;
  border-radius: 10px;
  margin-right: 20px;
  margin-bottom: 20px;
}

.remote-participants {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 1em 20px;
  background: #202124;
  height: 100%;
  align-items: center;
}

.participant {
  padding: 25px 0px;
  border-radius: 6px;
  display: inline-block;
  margin-right: 10px;
  width: 70%;
}

.participant-local {
  width: 80%;
}

.local-room {
  width: 100%;
  padding: 0;
  height: 100%;
  background: #3c4043;
}
.participant:last-child {
  margin-right: 0;
}
.participant-avatar {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.participant h3 {
  text-align: center;
  padding-bottom: 0.5em;
  color: #fff;
}

video {
  width: 100%;
  display: block;
  margin: 0 auto;
  border-radius: 10px;
  height: 100%;
  object-fit: cover;
}

footer {
  background: #333e5a;
  color: #fff;
  text-align: center;
  flex-grow: 0;
  padding: 1em 0;
}

footer a {
  color: #fff;
}

.icon-call {
  color: #fff;
}

.loading-call {
  display: flex;
  justify-content: center;
  margin-top: 100px;
  font-size: 20px;
  align-items: center;
}

.icon-items {
  display: flex;
  gap: 15px;
  justify-content: center;
  background: #202124;
  padding: 10px;
  position: fixed;
  bottom: 10px;
  right: 50%;
  translate: 50%;
  margin-bottom: 10px;
  border-radius: 30px;
}
.btn-icon {
  background: rgb(60, 64, 67);
  border: none;
  outline: none;
  width: 40px !important;
  height: 40px;
  text-align: center;
}
.btn-icon:active {
  background: rgb(60, 64, 67);
  border: none;
  outline: none;
  width: 40px !important;
  height: 40px;
  text-align: center;
}
.btn-icon:hover {
  background: rgb(60, 64, 67);
  width: 40px !important;
  height: 40px;
  text-align: center;
  border: none;
  outline: none;
}

.btn-icon:focus {
  background: rgb(60, 64, 67);
  width: 40px !important;
  height: 40px;
  text-align: center;
  border: none;
  outline: none;
}

.btn-icon-cancel {
  background-color: rgb(234, 67, 53);
  border: none;
  outline: none;
  width: 40px !important;
  height: 40px;
}
.btn-icon-cancel:hover {
  background-color: rgb(234, 67, 53);
  border: none;
  outline: none;
  width: 40px !important;
  height: 40px;
}
.btn-icon-cancel:focus {
  background-color: rgb(234, 67, 53);
  border: none;
  outline: none;
  width: 40px !important;
  height: 40px;
}
.room-empty {
  font-size: 50px;
  color: #fff;
  display: flex;
  align-items: center;
}

.room-info {
  position: absolute;
  left: 2%;
  bottom: 1%;
  color: #fff;
}

.participant-break {
  width: 40%;
  height: 52%;
  padding: 0;
}

.remote-participants-break {
  flex-wrap: wrap;
}

.remote-participants-max {
  justify-content: center;
  padding: 20px 120px;
  height: auto;
}
.participant-name {
  position: absolute;
  color: #fff;
  font-weight: bold;
  padding-left: 10px;
}
.participant-name-col-12 {
  bottom: 0%;
}
.participant-name-col-6 {
  bottom: 0%;
}
.participant-name-col-4 {
  bottom: 0;
}
.participant-name-col-3 {
  bottom: 0%;
}
.show-note-pad {
  height: 300px;
  font-size: 17px;
  padding: 15px;
  overflow-y: scroll;
}

.btn-icon[disabled],
.btn-icon[disabled]:hover,
.btn-icon[disabled]:focus,
.btn-icon[disabled]:active {
  background: #ccc;
}

@media only screen and (max-width: 1369px) {
  .col-3 {
    width: 450px;
    height: 280px;
  }
  .remote-participants-max {
    padding: 20px 10px;
  }
}

@media only screen and (min-width: 1540px) {
  .remote-participants-max {
    padding: 60px 120px;
  }
  .col-3 {
    width: 631px;
    height: 365px;
  }
}
@media only screen and (max-width: 950px) {
  .remote-participants {
    justify-content: space-between;
  }
  .col-3 {
    width: 49%;
    min-height: 48%;
    margin-right: 0;
  }
}
@media only screen and (max-width: 768px) {
  body {
    background: #202124;
  }

  .remote-participants {
    padding: 2em 15px;
    height: 94%;
    justify-content: space-between;
  }
  .col-md-12 {
    width: 100%;
    margin-bottom: 20px;
  }
  .col-6 {
    height: 50%;
  }
  .col-4 {
    height: 32%;
  }
  .col-3 {
    width: 49%;
    height: 48%;
    margin-right: 0;
  }

  .icon-items {
    padding: 10px;
  }
  .room-info {
    top: 0;
    left: 50%;
    bottom: 100%;
    transform: translateX(-50%);
  }
}
@media only screen and (max-width: 500px) {
  .icon-items {
    gap: 10px;
  }
  .ant-modal {
    width: 350px !important;
  }
  .room-info > p {
    font-weight: bold;
  }
  .ant-avatar {
    width: 110px !important;
    height: 110px !important;
  }
  .ant-avatar .ant-avatar-string {
    line-height: 120px !important;
  }
  .ant-avatar .ant-avatar-string > span {
    font-size: 60px !important;
  }
}
@media only screen and (max-width: 300px) {
  .icon-items {
    gap: 5px;
  }
}
.container-note-pad {
  position: relative;
}
.mic-off {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 10px;
  margin-top: 10px;
}
.btn-cancer:hover,
.btn-cancer:focus {
  color: #84cc45 !important;
  border-color: #84cc45 !important;
}
