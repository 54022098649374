.header-container {
  background-color: white;
}
.container-profile {
  background-color: #000000d4;
  padding: 15px;
  border-radius: 5px;
  margin: 0 15px;
}
.icon-activity {
  position: absolute;
  bottom: 0;
  right: -3px;
}
.logout-interpreter {
  background-color: #248046;
  color: white;
  min-height: 35px;
  border: none;
  outline: none;
}

.logout-interpreter:hover {
  background-color: #248046;
  color: white;
}
.logout-interpreter:focus {
  background-color: #248046;
  color: white;
}
.logout-interpreter:active {
  background-color: #248046;
  color: white;
}
.search-form-dashboard {
  display: flex;
  gap: 20px;
  width: 100%;
}
.container-table-dashboard {
  display: flex;
  gap: 20px;
}
.container-table-dashboard-mobile {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.table-dashboard {
  width: 100%;
  padding: 10px 0px;
}

/* INTERPRETER-TABLE */
.interpreter-enable {
  cursor: pointer;
  font-weight: bold;
  color: #ff5252;
}
.interpreter-disable {
  pointer-events: none;
}
.mt-3 {
  margin-top: 30px;
}
.my-2 {
  margin: 20px 0;
}
.text-center {
  text-align: center;
  display: block;
}