.btn {
    height: 40px;
    width: 40px;
    border: none;
    line-height: 40px;
    border-radius: 50%;
}
.btn-cancel, .btn-cancel:hover {
    background: #fa383e;
}
.btn-accept, .btn-accept:hover, .btn-accept:focus {
    background: #00a400;
}
.btn-cancel:hover, .btn-accept:hover  {
    filter: brightness(150%);
}

.btn-ok {
    margin-top: 10px;
}