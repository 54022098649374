.title-process-detail {
  padding: 20px 0;
}
.title-invoice {
  color: #ff5252 !important;
  font-size: 18px !important;
  padding: 10px 0px;
  font-weight: 550 !important;
  margin-top: 20px;
}
// .ant-picker {
//   border-bottom: 1px solid #d9d9d9 !important;
//   border-radius: 0px !important;
//   border: none;
// }
.process {
  border: none;
  outline: none;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  font-size: 15px;
}

.process:hover {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}
.process:active {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}
.process:focus {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}
.back {
  margin: 20px 0;
  padding: 0 25px;
}

.requester-information {
  padding-top: 30px;
  padding-bottom: 10px;
}
.title-edit-appointment {
  width: 95%;
}
.edit-appointment {
  width: 5%;
}
