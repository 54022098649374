.search-report-call {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
  margin-bottom: 30px;
}
.search-report-call:focus {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}
.search-report-call:active {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}
.search-report-call:hover {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}
.search-report-call:disabled:hover {
  border-color: gray;
  background: rgb(163, 162, 162);
  color: gray;
}
.search-form-report-call {
  display: flex;
  gap: 20px;
}
.search-item-call {
  width: 50%;
}

.search-report-call-mobile {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}
.search-report-call-mobile:hover {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}
.search-report-call-mobile:active {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}

.search-report-call-mobile:focus {
  border-color: #ff5252;
  background: #ff5252;
  color: white;
}
