.rate {
    font-size: 35px;
    margin-bottom: 10px;
    border: 1px;
}

.w-100 {
    background-color: #fff;
    width: 100%;
    min-height: 100vh;
}

.rate-space {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.rate-contain {
    width: 100%;
    min-height: inherit;
    align-items: center;
}

.rate-btn-submit {
    margin-top: 30px;
}

.rate-title {
    margin-bottom: 15px;
    text-align: center;
}

.wrapper-logo {
    display: flex;
    justify-content: center;
}

.img-logo {
    width: 120px;
    margin-bottom: 20px;
}

.card {
    width: 450px;
}

@media only screen and (max-width : 500px) {
    .card {
        width: 380px;
    }
}

@media only screen and (max-width : 400px) {
    .rate-contain {
        padding: 0 10px;
    }
    .card {
        width: 320px;
    }
}