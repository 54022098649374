.ant-layout-sider-trigger {
  top: 0;
  max-width: 360px;
  background:rgb(44, 52, 63);
  border-bottom: 1px solid #e6e6e6;
}

.contain-logo {
  margin-top: 60px;
  display: block;
}