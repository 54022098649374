.w-100 {
    background-color: #fff;
    width: 100%;
    height: 100vh;
}
.container {
    width: 100%;
    min-height: inherit;
    align-items: center;
}
.img-logo {
    width : 450px;
    margin-bottom: 20px;
}
.title {
    margin-bottom: 45px;
    text-align: center;
}

 @media only screen and (max-width : 500px) {
    .container {
        padding: 0 10px;
    }
     .img-logo {
         width: 100%;
         margin-bottom: 10px;
     }
     .title {
        font-size: 25px !important;
     }
  }